let ReferralStatus;
let ReferralRoute;
let HealthService;
define([
"OApp",
"OEncryption",
"referrals/OhlConstants"
], function(OApp, OEncryption, OhlConstants) { "use strict";
	HealthService = OhlConstants.HealthService;
	ReferralStatus = OhlConstants.ReferralStatus;
	ReferralRoute = OhlConstants.ReferralRoute;
	const Urgency = OhlConstants.Urgency;
	const Region = OhlConstants.Region;
	const WaitTimes = OhlConstants.WaitTimes;
	const Language = OhlConstants.Language;
	const OwnerAssignment = OhlConstants.OwnerAssignment;
	let shownPKLoadError = false;
	
	let Referrals = function() {
		let listeners = [];
		
		const CACHE_KEY = "oceanReferralKey";
		
		function getCachedKey(key) {
			return sessionStorage && sessionStorage.getItem(CACHE_KEY + key);
		}
		function setCachedKey(key, value) {
			try { sessionStorage.setItem(CACHE_KEY + key, value); } catch (e) {
				console.log("unable to cache referral key", e);//eg Safari private mode
			}
		}
		
		function init() {
		}
		function getServiceDesc(offerings) {
			let msg = "";
			if(offerings)
				for (let i = 0; i < offerings.length; i++) {
					if (i > 0) msg += ", ";
					msg += HealthService[offerings[i].service];
				}
			return msg;
		}
		function getRtDesc(rt) {
			let msg = rt.title;
			msg += "<br>"+StrUtil.escapeHtml(AddressUtil.addressToString(rt.contactInfo));
			msg += "<br><br><b>Services:</b><br>";
			for (let i = 0; i < rt.offerings.length; i++) {
				msg += Referrals.HealthService[rt.offerings[i].service] +"<br>";
			}
			return msg;
		}
		function isFastDecryption(referral) {
			return getCachedKey(referral.referralRef) || referral.oneTimeKey ? true : false;
		}
		function decryptReferralWithOneTimeKey(referral, oneTimeKey) {
			setCachedKey(referral.referralRef, oneTimeKey);
			referral.oneTimeKey = oneTimeKey;
			return OSymmetricEncryption.decryptPtData(referral.encryptedPtData, oneTimeKey);
		}
		function decryptReferralPt(referral, site, callback, failureCallback, skipPrompt) {
			let pt;
			let existingOTK = referral.oneTimeKey || OSymmetricEncryption.getOneTimeEncryptionKeyFromAnchor();
			if (!existingOTK && referral.oneTimeKeyEncryptedWithSitePublicKey) {
				// pull from the browser cache, but only if this referral's OTK is still available via the private key at this site:
				existingOTK = getCachedKey(referral.referralRef);
			}
			if (existingOTK) {
				pt = null;
				try {
					pt = decryptReferralWithOneTimeKey(referral, existingOTK);
				} catch (e) {
					console.error(e);
				}
				if (pt) {
					callback(pt);					
					return;
				}
			}
			OSymmetricEncryption.isSecretKeyEntered(function(keysEntered) {
				if (skipPrompt && !keysEntered) {
					failureCallback();
					return;
				}
				OApp.getSiteNum(function(curSiteNum) {
					const encryptedOTK = referral.siteAccessKeys[curSiteNum];
					// first try to use symmetric encryption:
					if (encryptedOTK) {
						decryptReferralPtWithSEK(referral, encryptedOTK, callback, failureCallback);
					} else if (site && referral.oneTimeKeyEncryptedWithSitePublicKey) {
						// no symmetric key is available; we have to use the private key instead
						loadPrivateKeys(site, function() {
							pt = decryptReferralPtWithPrivateKey(referral, site, referral.oneTimeKeyEncryptedWithSitePublicKey, failureCallback);
							if (pt) {
								callback(pt);
							}
						}, true);
					} else {
						failureCallback("can't decrypt - no site access");					
					}
				});
			});
		}
		function decryptReferralPtWithPrivateKey(referral, site, oneTimeKeyEncryptedWithSitePublicKey, failureCallback) {
			try {
				const oneTimeKey = OAsymmetricEncryption.decryptWithPrivateKeys(getCachedPrivateKeys(site), oneTimeKeyEncryptedWithSitePublicKey);
				return decryptReferralWithOneTimeKey(referral, oneTimeKey);
			} catch (e) {
				if (failureCallback) {
					failureCallback(e);
				} else {
					throw e;
				}
			}
		}
		function decryptReferralPtWithSEK(referral, encryptedKey, callback, failureCallback) {
			OSymmetricEncryption.decryptPtWithSecretKeys
				(encryptedKey, referral.encryptedPtData, function(pt, oneTimeKey) {
					referral.oneTimeKey = oneTimeKey;
					setCachedKey(referral.id, oneTimeKey);
					callback(pt, oneTimeKey);
				}, failureCallback);
		}
		
		// Pass true to "isRiiDto" when referral being passed is a ReferralDwrDto. False when it is
		// of a ReferralInitialInfoDto. These two DTOs hold the appropriate key in different fields
		function getReferralOTK(referral, site, isRiiDto) {
			return $.Deferred(function(d) {
				let oneTimeKey = null;
				const encryptedOTK = isRiiDto ? null : referral.siteAccessKeys[site.siteNum];
				if (encryptedOTK) {
					OSymmetricEncryption.decryptWithSecretKeys({
						encryptedOneTimeKey: encryptedOTK
					}, function(decryptedText, otk) {
						d.resolve(otk);
					});
				} else {
					// ReferralInitialInfo holds the otk encrypted with _sites_ pk in a different field compared to referral
					var otkEncryptedWithSitePublicKey = isRiiDto ? referral.oneTimeKeyEncryptedWithSrcPublicKey : referral.oneTimeKeyEncryptedWithSitePublicKey;
					oneTimeKey = referral.oneTimeKey ||
						getCachedKey(referral.referralRef) ||
						OAsymmetricEncryption.decryptWithPrivateKeys(getCachedPrivateKeys(site), otkEncryptedWithSitePublicKey);
					d.resolve(oneTimeKey);
				}
			});
		}
		
		const privateKeyCache = {};
		function getCachedPrivateKeys(site) {
			return privateKeyCache[site.privateKeysEncryptedWithSecretKey.iv];
		}
		function loadPrivateKeys(site, callback, checkToReEncrypt, skipCache) {
			function failedPKLoad(e) {
				if (!shownPKLoadError) {
					const msg = "The private encryption key for this site isn't accessible using the shared encryption key entered in this browser. " +
						"The private key is needed to decrypt and view incoming eRequests.";
					OUtils.multiAnswerAsk(msg, {
						"OK": function() { callback(); },
						"Show Error Details": function() {
							OSymmetricEncryption.getSecretKeys(function(keys) {
								OUtils.showNote(e, {title: "Encryption Key Error"});
								callback();
							});
						},
						"Enter New Shared Encryption Key to Access Private Key": function() {
							OSymmetricEncryption.getNewSecretKey(function() {
								loadPrivateKeys(site, callback, checkToReEncrypt, skipCache);
							});
						}
					}, "Private Key Inaccessible", {});
					shownPKLoadError = true;
				}
				else {
					callback();
				}
			}
			if (!site.privateKeysEncryptedWithSecretKey) {
				return;
			}
			if (!skipCache) {
				const cached = getCachedPrivateKeys(site);
				if (cached) {
					callback(cached);
					return;
				}
			}
			OSymmetricEncryption.getPrimarySecretKey(function() {
				OSymmetricEncryption.decryptWithSecretKeys({
					block: site.privateKeysEncryptedWithSecretKey,
					failureCallback: failedPKLoad
				}, function decrypted(privateKeysStr, oneTimeKey, sharedKeyUsed) {
					if (privateKeysStr) {
						const privateKeys = privateKeysStr.split("|");
						privateKeyCache[site.privateKeysEncryptedWithSecretKey.iv] = privateKeys;
						if (checkToReEncrypt) {
							requirejs(["referrals/RtKeySettings"], function(RtKeySettings) {
								RtKeySettings.checkToReEncryptPrivateKey(site, privateKeys, sharedKeyUsed);		
							});				
						}
						callback(privateKeys);
					} else {
						callback();
					}
				});
			}, failedPKLoad);
		}
		function resetShownPKLoadErrorFlag() {
			shownPKLoadError = false;
		}
		function getSearchStrForService(svcDesc) {
			switch (svcDesc) { //NOSONAR
				case HealthService.ADOLESCENT_MEDICINE: return "teenager adolescent clinic";
				case HealthService.BACK_PAIN: return "Back clinic";
				case HealthService.BIRTH_CONTROL: return "Birth Control contraception";
				case HealthService.COSMETICS: return "Cosmetic Clinic";
				case HealthService.COUNSELING: return "Mental Health Counseling";
				case HealthService.CT: return "ct scan";
				case HealthService.DENTAL_HYGIENE: return "Dental Hygienist";
				case HealthService.DENTISTRY: return "Dentist";
				case HealthService.DEVELOPMENTAL_AND_DISABILITY: return "Developmental and Disability Services";
				case HealthService.DIABETES_EDUCATION: return "Diabetes";
				case HealthService.DIAGNOSTIC_IMAGING: return "Diagnostic Imaging";
				case HealthService.DIAGNOSTIC_TESTS: return "";
				case HealthService.DIETITIAN: return "Registered Dietitian Nutritionist";
				case HealthService.DRIVING_SERVICES: return "Driver safety testing";
				case HealthService.ECG: return "electrocardiogram";
				case HealthService.EEG: return "electroencephalogram";
				case HealthService.EMG: return "electromyogram";
				case HealthService.ECHOCARDIOGRAM: return "Echocardiogram";
				case HealthService.EMERGENCY_SHELTER: return "Emergency Women's Shelter";
				case HealthService.FAMILY_SERVICES: return "Family counseling Services";
				case HealthService.FERTILITY: return "infertility clinic";
				case HealthService.FLUOROSCOPY: return "x-ray Fluoroscopy";
				case HealthService.GENETICS: return "Genetic Counseling";
				case HealthService.GERIATRICS: return "Geriatrician";
				case HealthService.GOVERNMENT_SERVICE_OFFICE: return "driver's license office";
				case HealthService.GYNECOLOGY: return "Gynecologist";
				case HealthService.HAIR_REMOVAL: return "Hair Removal Clinic";
				case HealthService.HEMATOLOGY: return "Hematologist";
				case HealthService.HEPATOLOGY: return "Hepatologist";
				case HealthService.HOLTER_MONITORING: return "Ambulatory ECG Monitoring (Holter)";
				case HealthService.HOUSING_AND_HOMELESSNESS_SERVICES: return "Housing and Homelessness Services";
				case HealthService.INFECTIOUS_DISEASE: return "Infectious Disease";
				case HealthService.INTERNAL_MEDICINE: return "Internal Medicine";
				case HealthService.KINESIOLOGY: return "Kinesiologist";
				case HealthService.LABORATORY: return "medical laboratories";
				case HealthService.LASER_SERVICES: return "Laser Services Hair Cosmetics";
				case HealthService.LLS_CHIROPODY_PODIATRY: return "Chiropodist Podiatrist";
				case HealthService.LOOP_MONITORING: return "Cardiac Loop Monitoring";
				case HealthService.MAMMOGRAPHY: return "Mammography";
				case HealthService.MASSAGE_THERAPY: return "Massage Therapy";
				case HealthService.MEAL_SERVICES: return "Elderly Senior Meal Services";
				case HealthService.MEDICAL_MARIJUANA: return "Medical Marijuana Clinic";
				case HealthService.MEDICAL_MICROBIOLOGY: return "Medical Microbiologist";
				case HealthService.MENTAL_HEALTH: return "Mental Health Counseling";
				case HealthService.MISCELLANEOUS: return "";
				case HealthService.MRI: return "Magnetic resonance imaging";
				case HealthService.NATUROPATHY: return "Naturopathic medicine";
				case HealthService.PAIN_MANAGEMENT: return "Chronic Pain Management";
				case HealthService.PALLIATIVE_CARE: return "Palliative Care Specialist";
				case HealthService.PALLIATIVE_HOMECARE: return "Palliative Homecare";
				case HealthService.PEDIATRIC_CARDIOLOGY: return "";
				case HealthService.PEDIATRIC_DENTISTRY: return "";
				case HealthService.PEDIATRIC_PSYCHIATRY: return "Pediatric Psychiatry";
				case HealthService.PEDIATRIC_SURGERY: return "";
				case HealthService.PEDIATRICS: return "Pediatrics";
				case HealthService.PHARMACY: return "pharma";
				case HealthService.RESPIRATORY_THERAPY: return "Respiratory Therapy Clinic";
				case HealthService.SEXUAL_HEALTH: return "Sexual Health Counseling";
				case HealthService.SLEEP_MEDICINE: return "Sleep Apnea Clinic";
				case HealthService.SMOKING_CESSATION: return "Smoking Cessation Counseling";
				case HealthService.SOCIAL_WORK: return "Social Work RSW";
				case HealthService.SPEECH_THERAPY: return "Speech Language Pathology";
				case HealthService.SPORT_THERAPY: return "Sports Medicine";
				case HealthService.SURGERY: return "Surgeon";
				case HealthService.TRAVEL_MEDICINE: return "Travel Medicine Clinic";
				case HealthService.ULTRASOUND: return "Radiology Ultrasound";
				case HealthService.VASCULAR_STUDIES: return "Venous Doppler Studies";
				case HealthService.X_RAY: return "Radiology X-Ray";
			}
			return svcDesc;
		}
		
		function addOrUpdateReferral(referral) {//called by server
			for (let i = 0; i < listeners.length; i++) {
				listeners[i].addOrUpdate(referral);
			}
		}
		function removeReferral(referral) {//called by server
			for (let i = 0; i < listeners.length; i++) {
				listeners[i].remove(referral);
			}
		}
		function addListener(l) {
			listeners.push(l);
		}
		function formatDistance(dist) {
			let distStr;
			if (dist === undefined || dist === null) {
				return "";
			}
			if (dist < 3) {
				distStr = dist.toFixed(2);
			} else {
				distStr = dist.toFixed(0);
			}
			return distStr + " km";
		}
		function getEmailDto(pt, saveDto) {
			return {
				ptPreferredName: pt.getPreferredOrFirstName(),
				ptEmail: pt.demographics.address.email,
				ptPreferredLanguage: pt.demographics.language,
				eFaxSenderRequestedPtNotification: !!(saveDto && saveDto.emailDto && saveDto.emailDto.eFaxSenderRequestedPtNotification),
			};
		}
		function getTerm(r, rt, caps) {
			if (r.eConsult) {
				return "eConsult";
			}
			if (r.selfReferral) {
				return caps ? "eRequest" : "request";
			}
			if (r.route === ReferralRoute.PRINTED_REFERRAL && rt && rt.rtType === "REPOSITORY") {
				return caps ? "Requisition" : "requisition";
			}
			return caps ? "Referral" : "referral";
		}
		function determineIfCreateEmrPt(referral, site, allowCreatePatientFromReferral) {
			const supportedEMRs = ["TELUS_PSS", "PSS_API", "MED_ACCESS", "ACCURO", "OSCAR", "OSCAR_PRO"];
			if (supportedEMRs.indexOf(site.emrType) === -1) {
				return false;
			}
			switch (referral.communicationType) {
				case "E_REFERRAL":
					return allowCreatePatientFromReferral;
				case "FORM_SUBMISSION":
					return site.createPatientFromWebsiteForm;
				case "ONLINE_BOOKING":
					return site.createPatientFromOnlineBooking;
				default:
					return false;
			}
		}
	
		return {	//Public Methods
			init: init,
			getTerm: getTerm,
			getServiceDesc: getServiceDesc,
			getRtDesc: getRtDesc,
			resetShownPKLoadErrorFlag: resetShownPKLoadErrorFlag,
			
			decryptReferralWithOneTimeKey: decryptReferralWithOneTimeKey,
			decryptReferralPt: decryptReferralPt,
			loadPrivateKeys: loadPrivateKeys,
			isFastDecryption: isFastDecryption,

			getSearchStrForService: getSearchStrForService,
			formatDistance: formatDistance,
			
			HealthService: HealthService,
			Region: Region,
			Urgency: Urgency,
			WaitTimes: WaitTimes,
			Language: Language,
			OwnerAssignment: OwnerAssignment,
			SMALL_SAMPLE: 10,
			getProfIdName: "Professional ID",
			
			addOrUpdateReferral: addOrUpdateReferral,
			removeReferral: removeReferral,
			getEmailDto: getEmailDto,
			addListener: addListener,
			getReferralOTK: getReferralOTK,
			determineIfCreateEmrPt: determineIfCreateEmrPt
		};
	}();
	return Referrals;
});
